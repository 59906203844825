<template>

  <div class="table-row clickable" :class="{ 'is-open': showDetails }" @click="toggleDetails">
    <div class="small">
      <button
        @click.stop="toggleDetails"
        :class="{ 'is-open': showDetails }"
        class="toggle-details-button small ghost"
      >
        <ChevronIcon />
      </button>
    </div>
    <div class="name">
      <img :src="dispatchMonitorEvent.iconUrl" />
      <template v-if="trigger.slug === 'address-balance-change'">
        <span :title="details.address" class="mr-1">{{ truncatedAddress }}</span> balance changed {{ details.changeAmount }} {{ details.contractSymbol }}
      </template>
      <template v-if="trigger.slug === 'balance-threshold-reached'">
        <span :title="details.address" class="mr-1">{{ truncatedAddress }}</span> balance {{ details.newBalance >= details.thresholdAmount ? '&gt;' : '&lt;' }} {{ details.thresholdAmount }} {{ details.contractSymbol }}
      </template>
      <template v-if="trigger.type === 'v2-dex'">
        {{ details.token1Symbol }} / {{ details.token2Symbol }} pair added to {{ details.dexName }}
      </template>
      <template v-if="trigger.type === 'v3-dex'">
        {{ details.token1Symbol }} / {{ details.token2Symbol }} pool added to {{ details.dexName }}
      </template>
      <template v-if="trigger.type === 'nft'">
        NFT transferred ({{ details.tokenName }})
      </template>
      <template v-if="trigger.type === 'user-contract'">
        <span class="font-mono mr-1">{{ details.signatureName }}</span> {{ details.signatureType === 'function' ? 'function called from' : 'event emitted by' }} {{ details.contractName }}
      </template>
    </div>
    <div class="timestamp">
      {{ formatTimestamp(dispatchMonitorEvent.insertedAt) }}
    </div>
  </div>
  <PatchEventDetails
    :show="showDetails"
    location="dispatch-monitor"
    :patchEvent="dispatchMonitorEvent"
  />

</template>

<script>

  import useFilters from '@/composables/useFilters'

  import ChevronIcon from '@/assets/icons/chevron.svg'

  import PatchEventDetails from '@/components/tables/PatchEventDetails.vue'

  export default {
    emits: ['toggle'],
    props: {
      dispatchMonitorEvent: {
        type: Object,
        required: true,
      },
    },
    components: {
      ChevronIcon,
      PatchEventDetails,
    },
    setup(props) {

      // composables
      const { formatTimestamp, truncateAddress } = useFilters()

      return Object.assign({
        truncateAddress,
        formatTimestamp,
      })

    },
    computed: {
      trigger() {
        return this.dispatchMonitorEvent.patch.trigger
      },
      details() {
        return this.dispatchMonitorEvent.details
      },
      truncatedAddress() {
        return this.truncateAddress(this.details.address)
      },
    },
    data() {
      return {
        showDetails: false,
      }
    },
    methods: {
      toggleDetails() {
        this.showDetails = !this.showDetails
        this.$emit('toggle', this.showDetails)
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .name
    @apply flex
    @apply flex-grow
    @apply items-start

    img
      @apply w-4
      @apply h-4
      @apply mt-1
      @apply mr-2
      @apply flex-shrink-0

</style>
