import { useStore } from 'vuex'
import { ref, onBeforeUnmount, computed } from 'vue'

export default (props) => {

  const isPolling = ref(false)

  let poll = null
  let timeoutId = null
  let numDetailsOpen = 0
  const defaultPollingInterval = 5000
  let wasPollingWhenVisibilityStateHidden = false

  const store = useStore()

  // computed
  const paginationList = computed(() => {
    return store.state.pagination[props.paginationListName.value]
  })

  // methods
  const stopPolling = () => {
    isPolling.value = false
    clearTimeout(timeoutId)
  }

  const startPolling = () => {
    timeoutId = setTimeout(poll, props.pollingInterval || defaultPollingInterval)
  }

  poll = () => {

    if (paginationList.value.currentPageNumber !== 1) {
      numDetailsOpen = 0
      startPolling()
      return
    }

    isPolling.value = true

    store.dispatch('pagination/LOAD_PAGE', { listName: props.paginationListName.value, silent: true })
      .catch(() => {
        // just ignore errors during polling
      })
      .finally(() => {
        if (!isPolling.value) return
        startPolling()
      })

  }

  const onVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      wasPollingWhenVisibilityStateHidden = isPolling.value
      stopPolling()
    } else if (wasPollingWhenVisibilityStateHidden) {
      startPolling()
    }
  }

  const detailsToggled = (isOpen) => {

    if (paginationList.value.currentPageNumber !== 1) return

    numDetailsOpen += isOpen ? 1 : -1

    if (numDetailsOpen === 0) {
      startPolling()
    } else {
      stopPolling()
    }

  }

  onBeforeUnmount(() => {
    stopPolling()
    document.removeEventListener('visibilitychange', onVisibilityChange)
  })

  startPolling()
  document.addEventListener('visibilitychange', onVisibilityChange)

  return {
    isPolling,
    stopPolling,
    startPolling,
    paginationList,
    detailsToggled,
  }

}
