<template>

  <Header />

  <main>
    <div class="container">

      <div class="upper-section">
        <h2>Dispatch Monitor</h2>
        <router-link
          class="button small"
          :class="{ 'disabled': isAPIReadOnly }"
          :to="{ name: isAPIReadOnly ? null : 'PatchCreate', params: { referrerLink: 'dispatch-monitor-header' } }"
        >
          Create Patch
        </router-link>
      </div>

      <template v-if="isUserDataLoading || isBootstrapDataLoading">
        <div class="loading-icon-container">
          <LoadingIcon />
        </div>
      </template>

      <template v-else>

        <template v-if="paginationList.totalItems === 0 && !paginationList.hasFiltersOrQueryApplied">
          <EmptyNotice>
            <template #image>
              <img src="@/assets/images/satellite.png" />
            </template>
            <template #title>
              There's no activity to display yet.
            </template>
            <template #message>
              If you have a Patch with Dispatch Monitor selected as the Action, you'll see it here every time the Trigger conditions are met.
            </template>
          </EmptyNotice>
        </template>

        <template v-else>

          <PaginationFilters :filterFormName="filterFormName" :paginationListName="paginationListName" />

          <template v-if="paginationList.totalItems === 0">
            <div class="relative">
              <LoadingOverlay type="light" v-if="paginationList.isLoading" />
              <EmptyNotice :showActionButton="false" :showPatchTemplates="false">
                <template #image>
                  <img src="@/assets/images/astronaut-sad.png" />
                </template>
                <template #title>
                  Sorry, nothing matches your search.
                </template>
                <template #message>
                  We couldn't find any Patch Events matching your search. Please try again.
                </template>
                <template #extra>
                  <button class="link mt-4" @click="resetFilterForm">Clear search</button>
                </template>
              </EmptyNotice>
            </div>
          </template>

          <template v-else>
            <div class="table-container">
              <LoadingOverlay type="light" v-if="paginationList.isLoading" />
              <div class="table">
                <div class="table-header table-row">
                  <div class="small"></div>
                  <div class="flex-grow">TRIGGER</div>
                  <div class="timestamp">TIMESTAMP</div>
                </div>
                <DispatchMonitorTableRow
                  @toggle="detailsToggled"
                  :key="dispatchMonitorEvent.id"
                  :dispatch-monitor-event="dispatchMonitorEvent"
                  v-for="dispatchMonitorEvent in paginationList.currentPage"
                />
              </div>
            </div>
            <PaginationButtons :paginationListName="paginationListName" />
          </template>
        </template>

      </template>

    </div>
  </main>

  <Footer />

</template>

<script>

  import { ref } from 'vue'
  import { mapState } from 'vuex'

  import usePaginationPolling from '@/composables/usePaginationPolling'
  import usePaginationFilters from '@/composables/usePaginationFilters'

  import Header from '@/components/page/Header.vue'
  import Footer from '@/components/page/Footer.vue'

  import EmptyNotice from '@/components/page/EmptyNotice.vue'
  import LoadingIcon from '@/components/utils/LoadingIcon.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import PaginationFilters from '@/components/utils/PaginationFilters.vue'
  import PaginationButtons from '@/components/utils/PaginationButtons.vue'
  import DispatchMonitorTableRow from '@/components/tables/DispatchMonitorTableRow.vue'

  export default {
    components: {
      Header,
      Footer,
      EmptyNotice,
      LoadingIcon,
      LoadingOverlay,
      PaginationFilters,
      PaginationButtons,
      DispatchMonitorTableRow,
    },
    setup() {

      const numDetailsOpen = ref(0)
      const filterFormName = ref('dispatchMonitorFiltersForm')
      const paginationListName = ref('dispatchMonitorEvents')

      const {
        filterForm,
        paginationList,
        resetFilterForm,
        numSelectedFilters,
      } = usePaginationFilters({ filterFormName, paginationListName })

      // @TODO: remove this when socket stuff is available
      const { detailsToggled } = usePaginationPolling({ paginationListName, numDetailsOpen })

      return {
        filterForm,
        paginationList,
        filterFormName,
        detailsToggled,
        resetFilterForm,
        paginationListName,
        numSelectedFilters,
      }

    },
    computed: {
      ...mapState('user', ['isUserDataLoading']),
      ...mapState('app', ['isAPIReadOnly', 'isBootstrapDataLoading']),
    },
    created() {
      this.$store.dispatch('pagination/RESET_PAGINATION', { listName: this.paginationListName })
    },
  }

</script>

<style lang="stylus" scoped>

  main
    @apply bg-white

    min-height: calc(100% - 128px)

  .container
    @apply p-4
    @apply mx-auto

  .upper-section
    @apply mb-6

    @apply flex
    @apply items-end

    +breakpoint(sm)
      @apply mt-24

    h2
      @apply mb-0
      @apply mr-8

    a
      @apply flex-shrink-0

  .loading-icon-container
    @apply my-16
    @apply flex
    @apply justify-center

</style>
