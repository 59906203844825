<template>

  <div
    v-show="show"
    @click="onClick"
     :class="[location]"
    ref="detailsContainer"
    class="details-container"
  >

    <div class="details">

      <template v-if="patchEvent.status === 'failure' && !!patchEvent.reason">
        <div class="alert error">
          {{ patchEvent.reason }}
        </div>
      </template>

      <section>

        <div class="timestamp">
          {{ formatTimestamp(patchEvent.insertedAt) }}
        </div>

        <template v-if="trigger.type === 'balance-change'">
          <strong>Detected balance change</strong>
          <div class="details-list with-border">
            <div>
              <strong>Address:</strong>
              <span><span class="break-all">{{ details.address }}</span> • <a target="_blank" :href="details.blockExplorerAddressLink" link-name="blockExplorerAddressLink">View on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></span>
            </div>
            <div>
              <strong>Network:</strong>
              <img class="inline-icon" :src="patchEvent.patch.network.iconUrl" />
              <span>{{ patchEvent.patch.network.name }}</span>
            </div>
            <div>
              <strong>Token:</strong>
              <img class="inline-icon" :src="patchEvent.patch.contract.iconUrl" />
              <span>{{ details.contractSymbol }} - {{ details.contractName }}</span>
            </div>
            <div v-if="trigger.slug === 'balance-threshold-reached'">
              <strong>Balance threshold:</strong>
              <span>{{ details.thresholdAmount }} {{ details.contractSymbol }}</span>
            </div>
            <div>
              <strong>Balance change:</strong>
              <span>{{ details.changeAmount }} {{ details.contractSymbol }}</span>
            </div>
            <div>
              <strong>New balance:</strong>
              <span>{{ details.newBalance }} {{ details.contractSymbol }}</span>
            </div>
          </div>
        </template>

        <template v-if="trigger.type === 'v2-dex'">
          <strong>Detected new {{ details.dexName }} {{ details.dexPairTerm }}</strong>
          <div class="details-list with-border">
            <div>
              <strong>New {{ details.dexPairTerm }}:</strong>
              <span>{{ details.token1Symbol }} / {{ details.token2Symbol }}</span>
            </div>
            <div>
              <ul>
                <li><strong>Address:</strong> <span class="break-all">{{ details.pairContractAddress }}</span></li>
                <li><a target="_blank" :href="details.dexPairInfoLink" link-name="dexPairInfoLink">View on {{ details.dexName }} <ExternalLinkIcon /></a></li>
                <li><a target="_blank" :href="details.blockExplorerLiquidityPairLink" link-name="blockExplorerLiquidityPairLink">View on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></li>
                <li><a target="_blank" :href="details.dexToolsPairExplorerLink" link-name="dexToolsPairExplorerLink">View on DexTools <ExternalLinkIcon /></a></li>
              </ul>
            </div>
            <div>
              <strong>{{ details.dexName }} {{ details.dexPairTerm }} reserves:</strong>
            </div>
            <div>
              <ul>
                <li>{{ details.token1ReserveAmount }} {{ details.token1Symbol }}</li>
                <li>{{ details.token2ReserveAmount }} {{ details.token2Symbol }}</li>
              </ul>
            </div>
            <div>
              <strong>{{ details.token1Name }} ({{ details.token1Symbol }}):</strong>
            </div>
            <div>
              <ul>
                <li><strong>Address:</strong> <span class="break-all">{{ details.token1ContractAddress }}</span></li>
                <li><a target="_blank" :href="details.dexToken1InfoLink" link-name="dexToken1InfoLink">View on {{ details.dexName }} <ExternalLinkIcon /></a></li>
                <li><a target="_blank" :href="details.blockExplorerToken1TokenLink" link-name="blockExplorerToken1TokenLink">View on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></li>
              </ul>
            </div>
            <div>
              <strong>{{ details.token2Name }} ({{ details.token2Symbol }}):</strong>
            </div>
            <div>
              <ul>
                <li><strong>Address:</strong> <span class="break-all">{{ details.token2ContractAddress }}</span></li>
                <li><a target="_blank" :href="details.dexToken2InfoLink" link-name="dexToken2InfoLink">View on {{ details.dexName }} <ExternalLinkIcon /></a></li>
                <li><a target="_blank" :href="details.blockExplorerToken2TokenLink" link-name="blockExplorerToken2TokenLink">View on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></li>
              </ul>
            </div>
            <div>
              <strong>{{ details.dexName }} factory {{ details.dexPairTerm }} number:</strong>
              <span>{{ details.dexFactoryPairNumber }}</span>
            </div>
            <div>
              <strong>{{ details.dexName }} {{ details.dexPairTerm }} creation TX hash:</strong>
            </div>
            <div>
              <span><span class="break-all">{{ details.txHash }}</span> • <a target="_blank" :href="details.blockExplorerTxLink" link-name="blockExplorerTxLink">view on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></span>
            </div>
            <div>
              <strong>{{ details.dexName }} {{ details.dexPairTerm }} creation block:</strong>
              <span>{{ details.blockNumber }} • <a target="_blank" :href="details.blockExplorerBlockLink" link-name="blockExplorerBlockLink">view on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></span>
            </div>
          </div>
        </template>

        <template v-if="trigger.type === 'v3-dex'">
          <strong>Detected new {{ details.dexName }} pool</strong>
          <div class="details-list with-border">
            <div>
              <strong>New pool:</strong>
              <span>{{ details.token1Symbol }} / {{ details.token2Symbol }} ({{ details.fee }})</span>
            </div>
            <div>
              <ul>
                <li><strong>Address:</strong> <span class="break-all">{{ details.poolContractAddress }}</span></li>
                <li><a target="_blank" :href="details.dexPoolInfoLink" link-name="dexPoolInfoLink">View on {{ details.dexName }} <ExternalLinkIcon /></a></li>
                <li><a target="_blank" :href="details.blockExplorerLiquidityPoolLink" link-name="blockExplorerLiquidityPoolLink">View on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></li>
                <li><a target="_blank" :href="details.dexToolsPoolExplorerLink" link-name="dexToolsPoolExplorerLink">View on DexTools <ExternalLinkIcon /></a></li>
              </ul>
            </div>
            <div>
              <strong>{{ details.token1Name }} ({{ details.token1Symbol }}):</strong>
            </div>
            <div>
              <ul>
                <li><strong>Address:</strong> <span class="break-all">{{ details.token1ContractAddress }}</span></li>
                <li><a target="_blank" :href="details.dexToken1InfoLink" link-name="dexToken1InfoLink">View on {{ details.dexName }} <ExternalLinkIcon /></a></li>
                <li><a target="_blank" :href="details.blockExplorerToken1TokenLink" link-name="blockExplorerToken1TokenLink">View on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></li>
              </ul>
            </div>
            <div>
              <strong>{{ details.token2Name }} ({{ details.token2Symbol }}):</strong>
            </div>
            <div>
              <ul>
                <li><strong>Address:</strong> <span class="break-all">{{ details.token2ContractAddress }}</span></li>
                <li><a target="_blank" :href="details.dexToken2InfoLink" link-name="dexToken2InfoLink">View on {{ details.dexName }} <ExternalLinkIcon /></a></li>
                <li><a target="_blank" :href="details.blockExplorerToken2TokenLink" link-name="blockExplorerToken2TokenLink">View on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></li>
              </ul>
            </div>
            <div>
              <strong>Pool creation TX hash:</strong>
            </div>
            <div>
              <span><span class="break-all">{{ details.txHash }}</span> • <a target="_blank" :href="details.blockExplorerTxLink" link-name="blockExplorerTxLink">view on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></span>
            </div>
            <div>
              <strong>Pool creation block:</strong>
              <span>{{ details.blockNumber }} • <a target="_blank" :href="details.blockExplorerBlockLink" link-name="blockExplorerBlockLink">view on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></span>
            </div>
          </div>
        </template>

        <template v-if="trigger.type === 'nft'">
          <strong>Detected NFT transfer</strong>
          <div class="details-list with-border">
            <div class="nft-info-container">
              <NFTInfo :patchEvent="patchEvent" />
            </div>
            <div>
              <strong>Collection:</strong>
              <img class="inline-icon" :src="patchEvent.patch.contract.iconUrl" />
              <span>{{ details.collectionName }}</span>
            </div>
            <div>
              <strong>Network:</strong>
              <img class="inline-icon" :src="patchEvent.patch.network.iconUrl" />
              <span>{{ patchEvent.patch.network.name }}</span>
            </div>
            <div>
              <strong>From:</strong>
              <span class="break-all">{{ details.fromAddress }}</span>
            </div>
            <div>
              <strong>To:</strong>
              <span class="break-all">{{ details.toAddress }}</span>
            </div>
            <div>
              <strong>TX hash:</strong>
            </div>
            <div>
              <span><span class="break-all">{{ details.txHash }}</span> • <a target="_blank" :href="details.blockExplorerTxLink" link-name="blockExplorerTxLink">view on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></span>
            </div>
            <div>
              <strong>Block:</strong>
              <span>{{ details.blockNumber }} • <a target="_blank" :href="details.blockExplorerBlockLink" link-name="blockExplorerBlockLink">view on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></span>
            </div>
            <div>
              <span><a target="_blank" :href="details.nftMarketplaceCollectionLink" link-name="nftMarketplaceCollectionLink">View on {{ details.nftMarketplaceName }} <ExternalLinkIcon /></a></span>
              <span>•</span>
              <span><a target="_blank" :href="details.blockExplorerCollectionLink" link-name="blockExplorerCollectionLink">View on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></span>
            </div>
          </div>
        </template>

        <template v-if="trigger.type === 'user-contract'">
          <strong>Detected smart contract {{ details.signatureType === 'function' ? 'function call' : 'event emission' }}</strong>
          <div class="details-list with-border">
            <div>
              <strong>Contract:</strong>
              <img class="inline-icon" :src="patchEvent.patch.contract.iconUrl" />
              <span>{{ details.contractName }}</span>
            </div>
            <div>
              <strong>Network:</strong>
              <img class="inline-icon" :src="patchEvent.patch.network.iconUrl" />
              <span>{{ patchEvent.patch.network.name }}</span>
            </div>
            <div>
              <strong>Contract type:</strong>
              <img class="inline-icon" :src="patchEvent.patch.network.iconUrl" />
              <span>{{ patchEvent.patch.contract.type === 'other' ? titleCase(patchEvent.patch.contract.type) : patchEvent.patch.contract.type.toUpperCase() }}</span>
            </div>
            <div>
              <strong>Contract address:</strong>
              <img class="inline-icon" :src="patchEvent.patch.contract.iconUrl" />
              <span><span class="break-all">{{ details.contractAddress }}</span> • <a target="_blank" :href="details.blockExplorerContractLink" link-name="blockExplorerContractLink">View on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></span>
            </div>
            <div>
              <strong>{{ titleCase(details.signatureType || 'Event') }}:</strong>
              <div class="break-all">
                <ContractABIEntrySignature :abiEntrySignature="details.signatureDisplay || details.signature" />
                <ContractEventValues class="mt-2" :eventValues="details.values" />
                <button class="mt-3 small secondary" @click="showJSON = !showJSON">{{ showJSON ? 'Hide' : 'Show' }} JSON</button>
                <code class="json-container mt-3" v-if="showJSON">
                  <button class="copy-button link" @click="copyJSON()"><CopyIcon /></button>
                  <span v-html="highlightJSON(this.detailsJSON)" />
                </code>
              </div>
            </div>
            <div>
              <strong>TX hash:</strong>
            </div>
            <div>
              <span><span class="break-all">{{ details.txHash }}</span> • <a target="_blank" :href="details.blockExplorerTxLink" link-name="blockExplorerTxLink">view on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></span>
            </div>
            <div>
              <strong>Block:</strong>
              <span>{{ details.blockNumber }} • <a target="_blank" :href="details.blockExplorerBlockLink" link-name="blockExplorerBlockLink">view on {{ details.blockExplorerName }} <ExternalLinkIcon /></a></span>
            </div>
          </div>
        </template>

      </section>

      <section>
        <div class="timestamp">
          {{ formatTimestamp(patchEvent.insertedAt) }}
        </div>
        <strong>{{ actionSentence }}</strong>
        <div class="details-list with-border">
          <div>
            <strong>Patch:</strong>
            <span>{{ patchEvent.patch.name }}</span>
          </div>
          <div v-if="action.slug === 'webhook' && accountIntegration.defaultOutput">
            <strong>Webhook URL:</strong>
            <span class="break-all">{{ accountIntegration.defaultOutput }}</span>
          </div>
          <div>
            <strong>Patch ID:</strong>
            <span>{{ patchEvent.patch.id }}</span>
          </div>
          <div>
            <strong>Event ID:</strong>
            <span>{{ patchEvent.id }}</span>
          </div>
          <div v-if="!isProduction">
            <strong>
              Queue ID:
              <span class="rarity-label common">
                STAGING
              </span>
            </strong>
            <span>{{ patchEvent.eventId }}</span>
          </div>
        </div>
      </section>

      <button class="mt-4 small" @click="copyDetails" v-if="showCopyButton">Copy Details</button>

    </div>

  </div>

</template>

<script>

  import { mapGetters } from 'vuex'

  import useFilters from '@/composables/useFilters'

  import NFTInfo from '@/components/page/NFTInfo.vue'

  import CopyIcon from '@/assets/icons/copy-solid.svg'
  import ExternalLinkIcon from '@/assets/icons/external-link.svg'

  import ContractEventValues from '@/components/etc/ContractEventValues.vue'
  import ContractABIEntrySignature from '@/components/etc/ContractABIEntrySignature.vue'

  export default {
    inject: ['$mixpanel'],
    components: {
      NFTInfo,
      CopyIcon,
      ExternalLinkIcon,
      ContractEventValues,
      ContractABIEntrySignature,
    },
    props: {
      patchEvent: {
        type: Object,
        required: true,
      },
      show: {
        type: Boolean,
        required: true,
      },
      location: {
        type: String,
        required: true,
        validator: (value) => {
          return (
            value === 'patch-history'
            || value === 'dispatch-monitor'
            || value === 'patch-event-modal'
          )
        },
      },
    },
    setup(props) {

      // composables
      const { titleCase, highlightJSON, formatTimestamp } = useFilters()

      return Object.assign({
        titleCase,
        highlightJSON,
        formatTimestamp,
      })

    },
    computed: {
      ...mapGetters('app', ['getActionSettingBySlug']),
      action() {
        return this.patchEvent.patch.action
      },
      trigger() {
        return this.patchEvent.patch.trigger
      },
      details() {
        return this.patchEvent.details
      },
      accountIntegration() {
        return this.accountIntegrationSetting.integration || {}
      },
      accountIntegrationSetting() {
        return this.getActionSettingBySlug(this.patchEvent.patch, 'integration') || {}
      },
      actionSentence() {
        switch (this.action.slug) {

          case 'email': {
            const email = this.getActionSettingBySlug(this.patchEvent.patch, 'email-address').value
            return `Sent an email to ${email}`
          }

          case 'telegram':
            return `Sent a Telegram message to ${this.accountIntegration.name}`

          case 'discord': {
            if (this.accountIntegration.serverId === null) {
              return `Sent a Discord direct message to ${this.accountIntegration.name}`
            }
            return `Sent a Discord message to ${this.accountIntegrationSetting.value} in ${this.accountIntegration.name}`
          }

          case 'dispatch-monitor':
            return 'Logged to Dispatch Monitor'

          case 'webhook':
            return `Sent a JSON payload to ${this.accountIntegration.name}`

          default:
            return 'Details'
        }

      },
      detailsJSON() {

        const json = this.patchEvent.details

        // remove some things we don't necessarily want to show to the user
        delete json.patchEventId
        delete json.patchEventLink

        return json

      },
    },
    data() {
      return {
        showJSON: false,
        clickCounter: 0,
        clickTimeout: null,
        showCopyButton: false,
        isProduction: process.env.VUE_APP_ENV === 'production',
      }
    },
    watch: {
      show(newValue, oldValue) {
        if (!newValue) return
        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('Patch Event Details Viewed', {
            details: this.details,
            location: this.location,
            patch: this.patchEvent.patch,

            actionName: this.action.name,
            triggerName: this.trigger.name,
            networkName: this.patchEvent.patch.network ? this.patchEvent.patch.network.name : null,
            contractName: this.patchEvent.patch.contract ? this.patchEvent.patch.contract.name : null,

            actionSlug: this.action.slug,
            triggerSlug: this.trigger.slug,
            networkSlug: this.patchEvent.patch.network ? this.patchEvent.patch.network.slug : null,
            contractSymbol: this.patchEvent.patch.contract ? this.patchEvent.patch.contract.symbol : null,
          })
        })
      },
    },
    methods: {
      copyDetails() {
        navigator.clipboard.writeText(JSON.stringify(this.details, null, '  '))
      },
      onClick(event) {

        if (event.target.tagName === 'A' && event.target.getAttribute('target') === '_blank') {
          this.$mixpanel.onReady((mixpanel) => {
            mixpanel.track('Patch Event Details Link Clicked', {
              location: this.location,
              linkName: event.target.getAttribute('link-name'),
              linkDestination: event.target.getAttribute('href'),

              details: this.details,
              patch: this.patchEvent.patch,

              actionName: this.action.name,
              triggerName: this.trigger.name,
              networkName: this.patchEvent.patch.network ? this.patchEvent.patch.network.name : null,
              contractName: this.patchEvent.patch.contract ? this.patchEvent.patch.contract.name : null,

              actionSlug: this.action.slug,
              triggerSlug: this.trigger.slug,
              networkSlug: this.patchEvent.patch.network ? this.patchEvent.patch.network.slug : null,
              contractSymbol: this.patchEvent.patch.contract ? this.patchEvent.patch.contract.symbol : null,
            })
          })
        }

        this.clickCounter += 1
        clearTimeout(this.clickTimeout)

        if (this.clickCounter >= 5) {
          this.clickCounter = 0
          this.showCopyButton = !this.showCopyButton

        } else {
          this.clickTimeout = setTimeout(() => {
            this.clickCounter = 0
          }, 500)
        }

      },
      copyJSON() {
        console.log(this.patchEvent)
        try {
          navigator.clipboard.writeText(JSON.stringify(this.detailsJSON, null, '  '))
          this.$store.dispatch('toast/CREATE_TOAST', {
            text: 'JSON copied!',
            type: 'success',
          })
        } catch (error) {
          this.$store.dispatch('toast/CREATE_TOAST', {
            text: 'Could not copy JSON. Your browser may not support this feature.',
            type: 'error',
          })
        }
      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/rarity.styl'
  @import '~@/assets/styles/table-row-details.styl'

  .json-container
    @apply relative

    .copy-button
      svg
        @apply w-4
        @apply h-4
        @apply text-primary-500

        @apply top-3
        @apply right-3
        @apply absolute

</style>
