<template>
  <div class="empty-notice">
    <div class="image-container">
      <slot name="image">
        <img src="@/assets/images/satellite-dish.png" />
      </slot>
    </div>
    <h5><slot name="title" /></h5>
    <p><slot name="message" /></p>
    <slot name="extra" />
    <slot name="action-button" v-if="showActionButton">
      <router-link
        class="button small"
        :class="{ 'disabled': isAPIReadOnly }"
        :to="{ name: isAPIReadOnly ? null : 'PatchCreate', params: { referrerLink: 'empty-notice' } }"
      >
        Create Patch
      </router-link>
    </slot>
  </div>
  <div class="patch-templates" v-if="showPatchTemplates">
    <h4>Looking for inspiration? Get started with one of these templates.</h4>
    <div class="card-list">
      <PatchTemplateCard
        :key="patchTemplate.id"
        :patchTemplateSlug="patchTemplate.slug"
        v-for="patchTemplate in randomPatchTemplates"
      />
    </div>
    <div class="footnote">
      <router-link to="/explore">Explore more templates</router-link>
    </div>
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  import PatchTemplateCard from '@/components/etc/PatchTemplateCard.vue'

  import useRandomPatchTemplates from '@/composables/useRandomPatchTemplates'

  export default {
    props: {
      showPatchTemplates: {
        type: Boolean,
        default: true,
      },
      showActionButton: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      PatchTemplateCard,
    },
    setup() {

      const { randomPatchTemplates } = useRandomPatchTemplates(4)

      return {
        randomPatchTemplates,
      }

    },
    computed: {
      ...mapState('app', ['isAPIReadOnly']),
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/card-list.styl'

  .empty-notice
    @apply p-12
    @apply bg-purple-100

    @apply flex
    @apply flex-col
    @apply items-center

    .image-container
      @apply p-10
      @apply mb-8
      @apply bg-white
      @apply rounded-full

      img
      :deep(img)
        @apply w-28
        @apply h-28

    h5
      @apply mb-1
      @apply text-gray-1000

    p
      @apply text-gray-800

    a
    button
    :deep(button)
      @apply mt-4

  .patch-templates
    @apply mt-16

    h4
      @apply mb-6

    .footnote
      @apply my-6
      @apply text-center

    // hide the 4th card when the grid has 3 columns
    .card:nth-child(4)
      +breakpoint-between(md, xl)
        @apply hidden

</style>
