<template>
  <div class="event-values">
    <div
      :key="index"
      v-for="(eventValue, index) in eventValues"
    >
      <div class="space-x-2">
        <span class="argument-type">{{ eventValue.type }}</span>
        <span class="argument-name">{{ eventValue.name }}</span>
      </div>
      <div>
        <span class="event-value">{{ eventValue.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      eventValues: {
        type: Array,
        required: true,
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .event-values
    @apply pl-3
    @apply text-sm
    @apply font-bold
    @apply font-mono
    @apply space-y-2

    border-left: 2px solid transparent
    border-image: linear-gradient(to bottom, theme('colors.gray.500'), theme('colors.gray.400')) 1

    .argument-type
      @apply text-purple-500

    .event-value
      @apply font-normal

</style>
