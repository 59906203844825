<template>

  <div class="nft-info">
    <div class="image">
      <a target="_blank" :href="details.imageUrl">
        <img :alt="`Image of ${details.tokenName}`" :src="details.imageUrlThumbnail" />
      </a>
    </div>
    <div class="info">
      <h5>{{ details.tokenName }}</h5>
      <div class="flex items-center">
        <span class="rarity-label mr-2" :class="[paramCase(details.rarityLabel)]" v-if="!details.skipRarity">
          {{ details.rarityLabel }}
        </span>
        <span :class="[paramCase(details.rarityLabel)]">
          <strong>Token ID:</strong> {{ details.tokenId }}
        </span>
      </div>
      <div class="rarity-info" v-if="!details.skipRarity">
        <div class="rarity-score">
          <div>
            <strong>Rarity score</strong>
            <Tooltip width="10rem" class="ml-1 self-center">
              <template #trigger>
                <InfoIcon />
              </template>
              <p class="mb-1">
                This score is calculated using the rarity of each property of the NFT.
                <br>
                <br>
                The higher the score, the rarer the NFT.
              </p>
            </Tooltip>
          </div>
          <div>
            <Tooltip>
              <template #trigger>
                <span class="score">{{ details.rarityScoreNormalized }}</span>
              </template>
              {{ details.rarityScoreNormalized }}
            </Tooltip>
            / 100
          </div>
        </div>
        <div class="rarity-rank">
          <div>
            <strong>Rarity rank</strong>
          </div>
          <div>
            <Tooltip>
              <template #trigger>
                <span class="rank">#{{ details.rarityRank }}</span>
              </template>
              #{{ details.rarityRank }}
            </Tooltip>
            / {{ details.rarityRankMax }}
          </div>
        </div>
      </div>
      <div v-if="!showProperties">
        <ul>
          <li><button class="link" @click="openNFTPropertiesModal()">View properties</button></li>
          <li><a target="_blank" :href="details.nftMarketplaceTokenLink" link-name="nftMarketplaceTokenLink">View on {{ details.nftMarketplaceName }} <ExternalLinkIcon /></a></li>
        </ul>
      </div>
      <div v-else>
        <h6>Properties</h6>
        <div>
          <div
            class="attribute"
            :key="attribute.name"
            v-for="attribute in details.attributes"
          >
            <div class="name">{{ attribute.name }}</div>
            <div class="value">{{ attribute.value || 'None' }}</div>
            <div class="percentage">{{ attribute.percentage }} have this</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

  import useFilters from '@/composables/useFilters'

  import Tooltip from '@/components/utils/Tooltip.vue'

  import InfoIcon from '@/assets/icons/info.svg'
  import ExternalLinkIcon from '@/assets/icons/external-link.svg'

  export default {
    components: {
      Tooltip,
      InfoIcon,
      ExternalLinkIcon,
    },
    props: {
      patchEvent: {
        type: Object,
        required: true,
      },
      showProperties: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const { paramCase } = useFilters()
      return { paramCase }
    },
    computed: {
      details() {
        return this.patchEvent.details
      },
    },
    methods: {
      openNFTPropertiesModal() {
        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'NFTPropertiesModal',
          mode: 'wide',
          props: {
            patchEvent: this.patchEvent,
          },
        })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/rarity.styl'

  ul
    @apply ml-5 !important

  a
    svg
      @apply w-3
      @apply h-3
      @apply inline
      @apply align-baseline

  .nft-info
    @apply flex
    @apply flex-col
    @apply items-start

    +breakpoint(sm)
      @apply flex-row

  .info
    > div + div
      @apply mt-3

  h5
    @apply mb-2

  h6
    @apply mb-2
    @apply text-base
    @apply text-gray-800

  .image
    @apply mb-3
    @apply rounded
    @apply flex-shrink-0

    +breakpoint(sm)
      @apply mb-0
      @apply mr-3

    a
      @apply p-0
      @apply inline-block

    img
      @apply w-full
      @apply rounded
      @apply inline-block
      @apply object-contain

      max-width: 16rem
      max-height: 32rem

  .attribute
    @apply p-1
    @apply mr-2
    @apply mb-2
    @apply border
    @apply rounded
    @apply border-primary-200

    @apply text-xs
    @apply text-gray-600

    @apply inline-block

    .name
      @apply font-bold

    .value
      @apply text-base
      @apply font-bold
      @apply text-gray-800

</style>
